import * as React from "react";

const LinkedIn = ({ className }: { className?: string }) => {
  return (
    <a
      href="https://linkedin.com/in/jewerspeters"
      rel="noopener noreferrer"
      target="_blank"
      aria-label="Visit me on Linked In"
      className="flex h-full w-full flex-col content-center items-center justify-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0,0,256,256"
        fillRule="nonzero"
        className={className}
      >
        <g
          strokeWidth="1"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
        >
          <g transform="scale(10.66667,10.66667)">
            <path d="M19,3h-14c-1.105,0 -2,0.895 -2,2v14c0,1.105 0.895,2 2,2h14c1.105,0 2,-0.895 2,-2v-14c0,-1.105 -0.895,-2 -2,-2zM9,17h-2.523v-7h2.523zM7.694,8.717c-0.771,0 -1.286,-0.514 -1.286,-1.2c0,-0.686 0.514,-1.2 1.371,-1.2c0.771,0 1.286,0.514 1.286,1.2c0,0.686 -0.514,1.2 -1.371,1.2zM18,17h-2.442v-3.826c0,-1.058 -0.651,-1.302 -0.895,-1.302c-0.244,0 -1.058,0.163 -1.058,1.302c0,0.163 0,3.826 0,3.826h-2.523v-7h2.523v0.977c0.325,-0.57 0.976,-0.977 2.197,-0.977c1.221,0 2.198,0.977 2.198,3.174z"></path>
          </g>
        </g>
      </svg>
    </a>
  );
};

export default LinkedIn;
