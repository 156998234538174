import * as React from "react";
import NavigationBar from "./navigation";
import LinkedIn from "./socials/linkedin";
import Email from "./socials/email";
import Phone from "./socials/phone";
import FooterLink from "./footerlink";

const Layout = ({ children }: { children: any }) => {
  return (
    <div id="top" className="flex min-h-screen flex-col">
      <header className="sticky top-0 z-50 flex h-[82px] w-full flex-col justify-center">
        <div className="h-[2px] w-full bg-tertiary" />
        <div className="flex h-20 w-full flex-row justify-center">
          <NavigationBar />
        </div>
      </header>
      <main className="flex flex-grow flex-col items-center">{children}</main>
      <footer className="flex h-fit w-full flex-col">
        <div className="h-[2px] w-full bg-tertiary" />
        <div className="flex w-full flex-col px-4 py-6 md:p-8">
          <div className="mx-auto grid w-fit grid-cols-1 grid-rows-1 content-start items-start justify-items-start gap-4 md:w-full md:max-w-7xl md:grid-cols-3">
            <div className="hidden h-full w-full content-center justify-start md:flex">
              <ul className="flex flex-col">
                <li>IT-Consulting Julian Ewers-Peters</li>
                <li>Brunnenstra&szlig;e 4</li>
                <li>49214 Bad Rothenfelde</li>
              </ul>
            </div>
            <div className="flex h-full w-full content-center justify-center">
              <ul className="mx-auto flex h-full flex-row content-start items-start space-x-4">
                <li>
                  <Phone className="h-8 w-8 fill-primary transition duration-200 ease-in-out hover:scale-125 hover:fill-highlight md:h-10 md:w-10" />
                </li>
                <li>
                  <Email className="h-8 w-8 fill-primary transition duration-200 ease-in-out hover:scale-125 hover:fill-highlight md:h-10 md:w-10" />
                </li>
                <li>
                  <LinkedIn className="h-8 w-8 fill-primary transition duration-200 ease-in-out hover:scale-125 hover:fill-highlight md:h-10 md:w-10" />
                </li>
              </ul>
            </div>
            <div className="flex h-full w-full content-center justify-end">
              <ul className="flex h-full flex-row space-x-2 md:flex-col md:space-x-0">
                <li>
                  <FooterLink title="Privacy Policy" link="/privacy#top" />
                </li>
                <li className="md:hidden">•</li>
                <li>
                  <FooterLink title="Impressum" link="/imprint#top" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
