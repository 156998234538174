import * as React from "react";
import NavigationItem from "./navigationitem";
import TitleLogo from "./titlelogo";
import Email from "./socials/email";
import LinkedIn from "./socials/linkedin";
import Phone from "./socials/phone";

const { useState } = React;

const NavigationBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const line =
    "h-1 w-8 my-1 rounded-full bg-white transition ease transform duration-300";

  return (
    <nav className="flex h-full w-full max-w-7xl flex-row items-center justify-between bg-main p-4">
      <div className="h-fit content-center items-center justify-center">
        <TitleLogo callback={() => setIsOpen(false)} />
      </div>
      {/*burger menu*/}
      <div
        className="flex h-12 w-12 flex-col items-center justify-center hover:cursor-pointer md:hidden"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          className={`${line} ${isOpen ? "translate-y-3 rotate-45" : ""}`}
        />
        <span className={`${line} ${isOpen ? "opacity-0" : ""}`} />
        <span
          className={`${line} ${isOpen ? "-translate-y-3 -rotate-45" : ""}`}
        />
      </div>
      {/*desktop navigation*/}
      <ul className="hidden h-16 bg-main md:relative md:top-0 md:right-0 md:flex md:h-fit md:w-fit md:flex-row md:justify-center md:space-y-0 md:bg-inherit">
        <li>
          <NavigationItem title="About" link="/#about" />
        </li>
        <li>
          <NavigationItem title="Hire" link="/#hire" />
        </li>
        <li>
          <NavigationItem title="References" link="/#references" />
        </li>
        <li>
          <NavigationItem title="Code" link="/#code" />
        </li>
        {/* <li>
          <NavigationItem
          title="Blog"
          link="https://blog.ewers-peters.de/"
          isExternal={true}
          />
        </li> */}
        <li>
          <NavigationItem title="Blog" link="/#blog" />
        </li>
        <li>
          <NavigationItem title="Apps" link="/apps#top" />
        </li>
      </ul>
      {/*mobile navigation*/}
      <div
        className={`fixed top-[82px] flex h-[100vh] w-full flex-col justify-start bg-main pt-[5vh] pb-[15vh] transition-all duration-700 md:hidden  ${
          isOpen ? "right-0" : "-right-[100vw]"
        }`}
      >
        <ul className="flex h-[70vh] w-full flex-col justify-center space-y-1 ">
          <li>
            <NavigationItem
              title="About"
              link="/#about"
              callback={() => setIsOpen(false)}
            />
          </li>
          <li>
            <NavigationItem
              title="Hire"
              link="/#hire"
              callback={() => setIsOpen(false)}
            />
          </li>
          <li>
            <NavigationItem
              title="References"
              link="/#references"
              callback={() => setIsOpen(false)}
            />
          </li>
          <li>
            <NavigationItem
              title="Code"
              link="/#code"
              callback={() => setIsOpen(false)}
            />
          </li>
          {/* <li>
            <NavigationItem
            title="Blog"
            link="https://blog.ewers-peters.de/"
            isExternal={true}
            />
          </li> */}
          <li>
            <NavigationItem
              title="Blog"
              link="/#blog"
              callback={() => setIsOpen(false)}
            />
          </li>
          <li>
            <NavigationItem
              title="Apps"
              link="/apps#top"
              callback={() => setIsOpen(false)}
            />
          </li>
          <li>
            <div className="mx-auto my-2 h-[1px] w-[40vw] bg-tertiary" />
          </li>
          <li>
            <div className="mx-auto flex w-fit content-start items-start justify-center p-1 2xs:p-2">
              <div className="flex h-full w-full content-center justify-center">
                <ul className="mx-auto flex h-full flex-row content-start items-start space-x-4">
                  <li>
                    <Phone className="h-8 w-8 fill-primary transition duration-200 ease-in-out hover:scale-125 hover:fill-highlight md:h-10 md:w-10" />
                  </li>
                  <li>
                    <Email className="h-8 w-8 fill-primary transition duration-200 ease-in-out hover:scale-125 hover:fill-highlight md:h-10 md:w-10" />
                  </li>
                  <li>
                    <LinkedIn className="h-8 w-8 fill-primary transition duration-200 ease-in-out hover:scale-125 hover:fill-highlight md:h-10 md:w-10" />
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <NavigationItem
              title="Privacy Policy"
              link="/privacy#top"
              callback={() => setIsOpen(false)}
            />
          </li>
          <li>
            <NavigationItem
              title="Impressum"
              link="/imprint#top"
              callback={() => setIsOpen(false)}
            />
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavigationBar;
