import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const NavigationItem = ({
  title,
  link,
  isExternal,
  callback,
}: {
  title: string;
  link: string;
  isExternal?: boolean;
  callback?: () => void;
}) => {
  if (isExternal == true) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => callback?.()}
      >
        <LinkDiv title={title} isExternal={true} />
      </a>
    );
  }

  return (
    <AnchorLink
      to={link}
      onAnchorLinkClick={() => callback?.()}
      stripHash={true}
    >
      <LinkDiv title={title} />
    </AnchorLink>
  );
};

const LinkDiv = ({
  title,
  isExternal,
}: {
  title: string;
  isExternal?: boolean;
}) => {
  return (
    <div className="mx-1 flex h-10 flex-col justify-center p-1 text-lg font-semibold text-accent-light hover:text-highlight 2xs:h-12 2xs:p-2 2xs:text-xl lg:ml-3 lg:mr-3">
      <div className="flex flex-row justify-center text-center align-middle">
        {title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className={`m-1 h-4 w-4 p-0 ${isExternal ? "visible" : "hidden"}`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
          />
        </svg>
      </div>
    </div>
  );
};

export default NavigationItem;
