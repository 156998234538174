import * as React from "react";
import { HiPhone } from "react-icons/hi2";

const Phone = ({ className }: { className?: string }) => {
  return (
    <a
      href="tel:+495424218872"
      aria-label="Call me"
      className="flex h-full w-full flex-col content-center items-center justify-center"
    >
      <HiPhone className={className} />
    </a>
  );
};

export default Phone;
