import "./src/styles/global.css";

import * as React from "react";
import Layout from "./src/components/layout";
import type { GatsbyBrowser } from "gatsby";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <Layout>{element}</Layout>;
};
