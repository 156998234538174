import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const FooterLink = ({ title, link }: { title: string; link: string }) => {
  return (
    <AnchorLink to={link} stripHash={true}>
      <div className="text-base font-semibold text-accent-light hover:text-highlight">
        {title}
      </div>
    </AnchorLink>
  );
};

export default FooterLink;
