import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const TitleLogo = ({ callback }: { callback?: () => void }) => {
  return (
    <AnchorLink
      to="/#top"
      onAnchorLinkClick={() => callback?.()}
      stripHash={true}
    >
      <StaticImage
        src="../images/logos/logo_EP_horizontal_white.png"
        alt="Ewers Peters logo"
        height={60}
        layout="constrained"
      />
    </AnchorLink>
  );
};

export default TitleLogo;
